@import '../../../sass/index.scss';

%title {
  font-size: 20px;
  font-weight: bold;
  border-left: 3px solid;
  border-color: $primary;
  display: block;
  padding: 0 10px;
  margin-bottom: 15px !important;
}

.inicio-tutor {
    .inicio-tutor__content {
      padding: 0 10%;
      color: $color-primary;
    .contadores {
        text-align: center;
        margin: 50px 0;
        .account-balance {
            font-size: 35px !important;
            font-weight: 600;
        }
        .contador.borde{
            border-left: 1px solid;
            border-color: #eee;
        }
    }
    .slider-info {
        .carousel-indicators .active {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }
    }
    .frase {
        
        margin: 50px 0;
       
        blockquote {
            color: $primary;
            font-family: Tahoma, sans-serif;
            font-size: 30px;
            width: 74%;
            margin: 90px auto;
            text-align: center;
            & h1 {
                font-size: 4rem;
             }
            & p {
                font-style: italic;
                margin-bottom: 0;
                font-size: 19px;
                font-weight: 300;
                text-align: center;
             }
             
            & p::before,
            & p::after 
            {
                content: "“";
                font-family: Georgia;
                font-size: 3rem;
                margin: -1rem 0 0 -1.5rem;
                position: absolute;
                opacity: 0.5;
             }
             
            & p::after {
                content: "”";
                margin: -.8rem -4rem 0 0;
             }
             
            & cite {
                font-size: .8rem;
             }
         }
    }
    .vision-mision {
        .vision {
            .titulo {
                @extend %title;
                
            }

            .texto {
                text-align: justify;
            }
        }

        .mision {
            .titulo {
                @extend %title;
            }

            .texto {
                text-align: justify;
            }
        }
    }
    }
    

}