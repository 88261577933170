@import '../../../sass/index.scss';

%padding-caja {
    padding: 20px 30px;
}
%item-listas {
    .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        p {
            margin-bottom: 2px;
        }
        p.indicador {
            font-weight: 500;
            font-size: 14px;
        }
        p.valor {
            padding-left: 15px;
        }
    }
}
%encabezado {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    border-bottom: 1px solid #f5f5f5;
    @extend %padding-caja;
    .titulo {
        i {
            margin-right: 5px;
        }
        font-weight: 500;
        color: $primary;
    }
    .boton-editar {
        i {
            color: $primary;
            font-size: 12px;
            margin-right: 3px;
        }
        a {
            color: $primary;
            &:hover {
                text-decoration: underline;
            }
        }
    }
  }

.perfil-empresa {
    
    .row {
        padding: 0 !important;
    }

    &__content-left {
        background: #fff;
        box-shadow: $style-shadow;

        .encabezado {
            @extend %encabezado;
        }

        .avatar.descripcion {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 30px;
            .logo img{
                max-width: 200px !important;
                height: 200px;
            }
            .nombre-empresa {
                margin: 20px 0;
                font-size: 15px;
                .abreviado {
                    font-weight: 500;
                }
                .completo {
                    padding: 0 60px;
                }
            }
        }
        .informacion-empresarial {
            @extend %padding-caja;
            @extend %item-listas;
        }
    }

    &__content-right {
        background: #fff;
        box-shadow: $style-shadow;

        .encabezado {
            @extend %encabezado;
        }

        .vision-mision {
            @extend %padding-caja;
            .contenido {
                .titulo {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        .info-bancaria {
            @extend %padding-caja;
            @extend %item-listas;
        }
        
    }
}