@import '.../../../../../../../sass/index.scss';

.tb-resumen-capacitacion {
  table {
    background-color: #fff;
    td {
      color: $color-primary;
      padding: 10px;
    }
    .tb-resumen-capacitacion__col--color {
      background-color: $primary;
      color: #fff;
      font-weight: 500;
      width: 190px;
    }
  } 
}