@import '../../../../../sass/index.scss';

.tabla-control-capacitaciones {
  .tabla {
    td {
      padding: 5px 5px !important;
      border-top: 1px solid #eee;
    }
    .tabla__td__modulo {
      min-width: 20rem;
    }
    .tabla__td__temas {
      min-width: 20rem;
    }
    .tabla__td--tentativo,
    .tabla__td--confirmado {
      min-width: 13rem;
    }
    .td__curriculum {
      min-width: 10rem;
      text-align: center;
      &__btn--color {
        background-color: transparent;
        color: $info !important;
        font-weight: 300;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .td__action {
      min-width: 10rem;
    }
  }
}