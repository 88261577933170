.PensionesItem {
  div, button {
    display: inline-block;
  }
  &__desc {
    display: flex;
    justify-content: space-between;
  }
  p {
    font-size: 1rem;
    line-height: 20px;
  }
  p:first-child {
    font-weight: 600;
  }
  button {
    margin-left: 1rem;
    margin-bottom: 30px;
  }
}