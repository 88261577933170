@import "../../../sass/index.scss";

.card-form {
  
  .card-action {
    outline: none;
    .title-card {
      font-weight: 200 !important;
      font-size: 20px;
    }

    .card-imagen {
      height: 100px !important;
    }
    .card-contenido {
      height: 120px;
      outline: none;
    }
  }
  .card-footer {
    background-color: #fff;
    height: 35px;
    display: flex;
    justify-content: space-between;
    .btn-shared, .btn-delete, .MuiIconButton-root {
      
      padding: 10px !important;
      text-transform: capitalize;
      outline: none;
    }
    .btn-delete {
      // background-color: $danger;
      // color: #fff;
      // margin-left: -11px;
      
      // svg {
      //   font-size: 20px;
      // }
    }

  }
}
