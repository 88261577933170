@import '../../../sass/index.scss';

.lista-sesiones {
  
  &__main-tabla {
    .header-tabla {
      .header-item {
        background: $primary !important;
        color: #fff;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
      }
    }
    
    .body-tabla {
      .fila {
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 0;
        font-size: 12px;
      }
      .fila.lleno {
        background-color: $success;
        color: #fff;
      }
      .fila-test {
        text-align: center;
        .btn-test {
          background-color: $success;
          color: #fff;
          font-size: 11px !important;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}