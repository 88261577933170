@import '../../../../../sass/index.scss';

.EditarTemasArea, .EditarTemasAreaTrabajo {
  .TemasSeleccionados {
    .TemasSeleccionados__lista {
      .lista__item {
        background-color: $light;
        button {
          background-color: $light;
          margin: 0;
        }
        span {
          user-select: none;
        }
      }
    }
  }
}