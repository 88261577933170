@import '../../../../../sass/index.scss';

.nueva-encuesta {
  
  .nav.nav-tabs {
    a.nav-item.nav-link {
      width: 50%;
      background-color: $light;
      padding: 10px;
      text-align: center;
      font-size: 14px;
      // box-shadow: inset 10px 10px 20px -10px #ccc;
    }
    a.nav-item.nav-link.active {
      background-color: #fff;
      border-top: 5px solid $primary;
      color: $primary !important;
      border-radius: 0;
      box-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
    }
  }
}