@import '../../../sass/index.scss';

.reporte-asistencia {

  .content-shearch {
    .contenido {
      width: 20% !important; 
      margin-right: 10px; 
    }
    button.btn.btn-primary {
      background-color: $primary !important;
      height: 35px;
      border: 0;
      svg {
        margin-right: 5px;
      }
    }
  }
  .spinner {
    width: 40px;
    height: 40px;
    
    position: relative;
    margin: 250px auto;
  }
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $primary;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }

  

  .left-content {
    .tabla {
      .encabezado {
        background-color: $primary !important;
        padding: 10px 10px;
        color: #fff !important;
        min-width: 100px;
        &.nombre {
          min-width: 120px;
          text-align: center;
        } 
        &.apellido {
          min-width: 120px;
          text-align: center !important;
        }
        &.acciones {
          min-width: 100px;
        }
      }
    }

    
  }

  .right-content {
    background-color: #fff;
    
    margin-top: 25px;
    .estadisticos {
      // display: flex;
      .title {
        background-color: #F5F5F5;
      }
      .grafico {
        margin: 50px 0;
        p {
          text-align: center;
          font-weight: bold;
          color: $primary;
          font-size: 15px;
          margin-bottom: 0px !important;
        }
      }
    }
    
  }
  
}