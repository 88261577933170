@import '../../../../sass/index.scss';

.registro-docente {
  form {
    .avatar-usuario {
      img {
        width: 50%;
        height: 14rem;
      }
    }
    label {
      color: $color-primary;
      font-weight: 500;
    }
    .form-row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}