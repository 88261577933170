@import '../../../sass/index.scss';

.mensaje-loading {
  .aviso-cargar {
    
    display: flex;
    min-height: 400px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    
    .anuncio {
      text-align: center;
      padding: 50px 0;
      img {
        width: 30%;
        margin-right: 15px;
        margin-bottom: 50px;
      }
      h3 {
        font-size: 25px;
      }
      h3, p {
        color: $primary; 
      }
      p {
        font-size: 13px;
      }

    }
    
  }
}