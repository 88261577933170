@import '../../../sass/index.scss';

.recursos {
    &__content-filtro {
        label {
            color: $primary;
            font-weight: bold !important;
        }
    }
    &__content-nuevo {
        
        align-items: center;
        justify-content: flex-end;
        display: flex;
        
    }

    .content-recursos-tab {
        margin-top: 3rem;
    }

}