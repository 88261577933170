@import '../../../../sass/index.scss';

.info-label {
  background-color: #fff;
  padding: 12px;
  border-left: 3px solid #1E2B37;
  font-size: 14px;
  font-weight: bold;
  color: #1E2B37;
  box-shadow: $style-shadow;
  &__mensaje {
    margin-bottom: 0;
  }
}