@import '../../../../sass/index.scss';

.tabla-resumen-evento-encuesta {
  table {
    tr {
      td {
        padding: 5px 10px;
        &.title {
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}