@import '../../../../../sass/index.scss';

.CurriculumDocente {
  h6 {
    font-weight: 500;
  }
  ul {
    margin: 0;
    padding-left: 1rem;
  }
}