@mixin max-heigth($max-heigth: 500px) {
  max-height: $max-heigth;
}

@mixin max-heigth-scroll($max-heigth: 500px) {
  @include max-heigth($max-heigth);
  overflow-y: auto;
}

//TABLAS

@mixin tableFixHead($height: 500px) {
  overflow-y: auto;
  max-height: $height;
  thead th {
    position: sticky;
    top: 0;
    z-index: 9;
  }
  td {
    padding: 5px 16px;
  } 
}
