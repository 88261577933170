#progress-bar-container {
	width: 100%;
	height: 12px;
	margin: 0 auto;
	position: relative;
	transform: translateY(-50%);
	border-radius: 35px;
	overflow: hidden;
    border:1px solid rgb(20, 19, 20)
}

#progress-bar-container .progress-bar-child {
	width: 100%;
	height: 100%;
}

#progress-bar-container .progress {
	color: white;
	text-align: center;
	line-height: 75px;
	font-size: 35px;
	font-family: "Segoe UI";
	animation-direction: reverse;
	background: #2ECC71;
	/* Chrome10-25,Safari5.1-6 */
	/*background: linear-gradient(to right, #e5405e 0%,#F39C12 25%, #ffdb3a 50%,#ffdb3a 75%, #2ECC71 100%);*/
}

#progress-bar-container .shrinker {
	background-color:#aba;
	position: absolute;
	top: 0;
	right: 0;
}
