ol.naranja li{
  padding: 10px;
  margin:10px 10px;
  font-size:18px;
}

.semaforo_rn{
  width:25px;
  height: 25px;
  background-color: gray;
  border:1px solid black;
  border-radius: 50%;
  display: block;

}

.verde{
  background-color: #239B56;
}
.ambar{
  background-color: #ff7e00 ;
}
.rojo{
  background-color: #CB4335;
}