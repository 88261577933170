@import '../sass/index.scss';


.left_col.scroll.view{
    background-color: $primary !important;
}

.nav-sm ul.nav.child_menu {
    background: $primary;
}

.menu_section {
    background: $primary;
    margin-bottom: 0;
    h3 {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 12px;
    }
}
.side-menu {
    background: $primary;
    .sidebar-footer {
    background-color: $primary;
        a {
            background: $primary;
            color: rgb(235, 235, 235);
        }
    }
    .menu_section.scroll-menu{
        overflow-y: auto;
        height: calc(100vh - 220px);
    }
}

.navigation {
    .btn-logout {
        background: $primary;
        color: #fff;
        margin-bottom: 2px;
        &:hover {
            background: $primary-hover;
            color: #fff;
        }
    }
}

.sidebar-footer span {
    color: #fff;
}

.nav.child_menu {
    list-style: none;
}

.nav.side-menu > li.active > a {
    box-shadow: none;
    background-color: darken($color: $primary, $amount: 3.5);
    &:hover {
        background-color: darken($color: $primary, $amount: 3.5);
    }
}

.nav.side-menu > li.current-page, .nav.side-menu > li.active {
    border-left: 5px solid $succes-light;
}

.nav.side-menu > li > a {
    margin-bottom: 0 !important;
}

.nav.side-menu > li.current-page, .nav.side-menu > li.active ul.child_menu {
    
    background-color: darken($color: $primary, $amount: 1.8);
    padding: 15px 0;
}

.nav li.current-page {
    background-color: #fff !important;
   
}
.nav li li.current-page a {
    color:#533333 !important;
}

.nav.side-menu > li > a, .nav.child_menu > li > a {
    color: #fff;
    font-weight: 500; 
    font-size: 13px !important;
}

.menu_section h3 {
    padding-left: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.nav.child_menu li {
    &:hover {
        background-color:  #ffffff;
        a {
            color: rgb(53, 51, 51) !important;
        }
    }
}

.nav.side-menu > li.current-page.individual {
    padding: 0;
    &.current-page {
        border-left-width: 0;
     
            background-color: transparent !important;
        
        a {
            color: #fff !important;
        }
    }
}
