@import "../../../../sass/index.scss";

.vista-encuesta {
  .main-encuesta {
    &__header {
      padding: 15px 10px;
      &:hover {
        background-color: #ccc;
        cursor: pointer;
        
      }
      h4 {
        font-size: 38px;
        font-weight: 300;
      }
    }
    &__descripcion,
    &__body {
      color: #333;
      font-weight: 300;
    }

    &__descripcion {
      font-size: 15px;
    }
    &__body {
      .body-encuesta__preguntas {
        .pregunta-enter {
          opacity: 0;
          transform: scale(0.98, 0.98);
          -webkit-transform: scale(0.98, 0.98);
          -moz-transform: scale(0.98, 0.98);
          -ms-transform: scale(0.98, 0.98);
          -o-transform: scale(0.98, 0.98);
        }

        .pregunta-enter.pregunta-enter-active {
          opacity: 1;
          transform: scale(1, 1);
          transition: all 200ms ease-in;
          -webkit-transition: all 200ms ease-in;
          -moz-transition: all 200ms ease-in;
          -ms-transition: all 200ms ease-in;
          -o-transition: all 200ms ease-in;
          -webkit-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          -o-transform: scale(1, 1);
        }

        .pregunta-leave {
          opacity: 1;
          transform: scale(0.7, 0.7);
        }

        .pregunta-leave.pregunta-leave-active {
          opacity: 0;
          transform: scale(0, 0);
          transition: all opacity 300ms ease-in;
          -webkit-transition: all opacity 300ms ease-in;
          -moz-transition: all opacity 300ms ease-in;
          -ms-transition: all opacity 300ms ease-in;
          -o-transition: all opacity 300ms ease-in;
        }

        .item__pregunta {
          padding: 40px 20px 20px;
          transition: background 0.2s ease;
          -webkit-transition: background 0.2s ease;
          -moz-transition: background 0.2s ease;
          -ms-transition: background 0.2s ease;
          -o-transition: background 0.2s ease;
          &:hover {
            background-color: #ccc;
            cursor: pointer;
          }

          &_start {
            margin-left: 20px;
          }
        }
        .pregunta {
          font-size: 20px;
        }
        .pregunta-decripcion {
          margin-left: 25px;
          margin-top: 10px;
          margin-bottom: 15px;
          font-size: 11px;
          font-weight: 200;
        }
        .textarea-pregunta {
          border: 1px solid $dark;
          font-size: 13px;
          background-color: transparent;
        }

          button {
            padding: 10px 15px;
            svg {
              margin-right: 5px;
            }
          }
          .btn-nueva-pregunta {
            
            background-color: $primary !important;
            color: #fff;
          }
          .btn-guardar {
            background-color: $danger !important;
          }
      }
    }
  }
}
