.Facturas {
  &__bottom--right.col-md-8 {
    padding-left: 100px;
  }
  .datos-recibo {
    .box-body p {
      font-weight: 700;
      span {
        font-weight: 500;
      }
    }
  }
}