@import '../../../../sass/index.scss';

.form-content {
  width: 40%;
  .form-select {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .detalles-eventos {
    .evento {
      color: $color-primary;
      span {
        font-weight: 700;
      }
    }
  }
}