@import "../../../sass/index.scss";

.singin-singup {
  background: $primary;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  &__content-form {
    width: 25%;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 50px;
    img {
      display: block;
      width: 150px;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 468px) {
      width: 90%;
    }
  }
}
