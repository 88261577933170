@import '../../../sass/index.scss';

.tareas {
    .tabla {

        .btn-eliminar {
            background: $danger;
            color: #fff;
            margin: 0;
            &:hover {
                background: $danger-hover;
            }
        }
        .btn-descrip {
            background: $info;
            color: #fff;
            margin: 0;
            &:hover {
                background: $info-hover;
            }
        }
        .btn-ver {
            background: $warning;
            color: #fff;
            margin: 0;
            &:hover {
                background: $warning-hover;
            }
        }
        
    }
}