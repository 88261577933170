@import "../../../../sass/index.scss";

.SpinnerLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  .spinner-border {
    margin-left: auto;
    margin-right: auto;
    margin-top: 300px;
    margin-bottom: 100px;
    &.text-primary {
      color: $primary !important;
    }
  }
}

