@import '../../../../sass/index.scss';

.FormBusquedaDiscente {
  &__container {
    background-color: #fff;
    padding: 1rem 1.1rem;
    margin-top: 15px;
  }
  .content-btn-submit {
    position: relative;
    button {
      position: absolute;
      bottom: 0;
      left: 0;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}