@import '../../../sass/index.scss';

.reporte-desertados {
  table {
    .td-discente {
      min-width: 300px;
    }
    .td-cobrar {
      min-width: 10rem;
      background-color: #faf089;
      color: #744210;
      font-weight: 500;
      border: 1px solid #fff;
      border-top: 0;
    }
    .td-cobrar-2 {
      min-width: 15rem;
    }
    .td-solicitud {
      min-width: 15rem;
    }
    .td-estado {
      min-width: 10rem;
    }
  }
}