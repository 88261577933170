@import './sass/';
@import './sass/base';
@import './sass/components/botones';
@import './sass/components/alert';
@import './sass/components/subitem';
@import './sass/components/tabs';
@import './sass/components/formularios';
@import './sass/components/tablas';
@import './sass/components/contenedores';

.nav-tabs .nav-link:focus{
  outline: none;
}


.tabla button, .table_tools span i{
  color: #fff !important;
}
/*BOTONES ACCIONES HEADER*/ 

button.acciones-header, button.acciones-header i{
  font-weight: 200 ;
  & .btn-sm {
    font-size: 14px;
  }
}
button.acciones-header i{
  font-size: 15px;
}


/***************
* Fondos 
************/

.bg-success {
  background-color: $success !important;
}


.elevation-4{
  box-shadow: none !important;
}
#as-react-datatable{
  border: none;
}
#as-react-datatable th.sortable{
  color: $color-primary;
}
#as-react-datatable-table-head{
  margin-top: 17px;
  margin-left: 5px;
  margin-right: 5px;
}
#as-react-datatable-table-body{
  margin-left: 5px;
  margin-right: 5px;
}
#as-react-datatable-table-foot{
  margin: 15px 5px;
  color: $color-primary;
}
.input-group.asrt-page-length {
  align-items: center;
}
.tabla.border{
  padding: 0 0 10px 0;
}
.table-bordered td, .table-bordered th{
  padding: 5px 10px !important;
  vertical-align: middle !important;
}
ul.nav.nav-tabs{
  border: none;
}
.table-bordered td{
  border: none;
}
.table tr{
  border-top: 1px solid #dee2e6 !important;
}
#as-react-datatable tr {
  border-top: 1px solid #ccea;
}
.aside p.text-white ._ffn_textNode{
  font-size: 14px !important;
}
/**/

.border-dashed{
  border-style: dashed !important;
}
.border-5{
  border-width: 5px !important;
}
.border-1{
  border-width: 1px !important;
}
.border-2{
  border-width: 2px !important;
}
.cursor-pointer{
  cursor: pointer;
}
li.nav-header{
  padding-top: 20px !important;
}
.hover-border-blue{
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
}

/*hover*/
.hover-border-blue:hover{
  border-color: #233142 !important;
 -webkit-box-shadow: 2px -3px 3px 0px rgba(35,49,66,1);
-moz-box-shadow: 2px -3px 8px 0px rgba(35,49,66,1);
box-shadow: 2px -3px 8px 0px rgba(35,49,66,1);
}

.table-striped tbody tr:nth-of-type(2n+1){
  background: #fff;
}
.continer-fluid.border-bottom-0.border h5{
  font-size: 14px;
  font-weight: bold;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  background: #F5F5F5;
}
.table-bordered td, .table-bordered th{
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
}
.header-title-page h2{
  font-size: 24px;
}
li.nav-item a:hover{
  background: #202b3a;
}
li.nav-item a:active{
  background: #202b3a;
}


.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused{
  min-height: 200px;
}


.nav-md .container.body .col-md-3.left_col{
  position: fixed;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.menu_section::-webkit-scrollbar {
  -webkit-appearance: none;
}

.menu_section::-webkit-scrollbar:vertical {
  width:5px !important;
}

.menu_section::-webkit-scrollbar-button:increment,.menu_section::-webkit-scrollbar-button {
  display: none !important;
} 

.menu_section::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

.menu_section::-webkit-scrollbar-thumb {
  background-color: #A5B0C4 !important;
  border-radius: 20px !important;
  border: 1px solid rgb(48, 63, 80) !important;
}

.menu_section::-webkit-scrollbar-track {
  border-radius: 10px !important;  
}
