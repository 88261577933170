@import '../../../../../sass/index.scss';

.AreaCiclos {
  .enlaces-globales {
    margin-bottom: 1rem;
    .card {
      display: table-cell;
    }
  }
  
}
