@mixin contenedorColor($color: $light) {
  &__header {
    background-color: $color;
    color: $color-primary;
    padding: 15px;
    width: 100%;
    h1, h2, h3, h4, h5 {
      font-weight: 400;
      svg {
        color: $secondary;
        margin-right: .5rem;
      }
    }
  }  
  &__content {
    background-color: #fff;
    padding: 15px;
  }
}

.contenedor-seccion {
  & {
    @include contenedorColor($light);
  }
}

.title-head {
  margin-bottom: 14px;
  font-size: 1.2rem;
  svg {
    margin-right: 5px;
  }
}

.box {
  background-color: #fff;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  .box-body {
    padding: 10px;
    hr {
      border-top-width: 2px;
      border-color: lighten($color: $dark, $amount: 30);
    }
  }
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  &.with-border {
    border-bottom: 1px solid #f4f4f4;
  }
  & .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
    font-weight: 500;
  }
}

.box-primary {
  border-top-color: $primary;
}
.box-secondary {
  border-top-color: $secondary;
}
.box-info {
  border-top-color: $info;
}