@import '../../../../../sass/index.scss';

.EditarClasificacion {

  .ListaRangoProfesional {
    .Category {
      &__title {
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }
  .RangoProfesionalSeleccionados {
    .Category {
      &__title {
        font-weight: 400;
        font-size: 1rem;
      }
      span {
        color: $color-primary;
      }
      .RangoProfesional {
        background-color: #eee;
        button {
          background-color: #eee;
        }
      }
    }
  }
}