@import '../../../../sass/index.scss';

.encuesta-reporte {
  &__encabezado {

  }

  &__cuerpo {
    .content-preguntas__item {
      .head {
        border-left: 3px solid $primary;
        padding-left: 13px;
      }
    }
  }
}