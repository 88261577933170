
//Botones

@mixin color($color) {
  background: $color;
  border-color: darken($color: $color, $amount: 5);
  &:hover, &:active, &:focus {
    background-color: darken($color, 8%) !important;
    border-color: $color;
    box-shadow: none;
  }
  &:disabled {
    background-color: $color;
    border-color: $color;
    box-shadow: none;
    opacity: .5;
  }
}
.btn {
  border-radius: 3px;
  font-size: 13px;
}

.btn-xs {
  padding: 1px 6px;
  font-size: 12px;
  line-height: 1.5;
}

@mixin color-outline-boton($color) {
  border-color: $color;
  color: $color !important;
  &:hover, &:active, &:focus {
    background-color: transparent !important;
    border-color: $color !important;
    box-shadow: none !important;
    outline: none;
  }
}
div .btn {
  margin-right: 3px;
  margin-bottom: 3px;
  &:last-child {
    margin-right: 0;
  }
}
.bg-primary { 
  background-color: $primary !important;
  border-color: $primary;
}

.btn-primary {
  @include color($primary);
}
.btn-danger {
  @include color($danger);
}
.btn-secondary {
  @include color($secondary);
}
.btn-success {
  @include color($success);
}

.btn-dark {
  @include color($dark);
}

.btn-warning {
  @include color($warning);
  color: #fff !important;
}

.btn-info {
  @include color($info);
}

.btn-light {
  @include color($light);
  border-color: #c4c4c4 !important;
}

.btn-icon {
  & > svg {
    margin-right: .5rem;
  }
}

.btn-outline-primary {
  @include color-outline-boton($primary);
}

/*
  Lista de Botones
**/

.buttons-list {
  button {
    margin-right: .3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

/*
  Botones fontawensome
*/

.btn-icon-scope {
  cursor: pointer;
  &.error {
    pointer-events: none;
    color: darken($color: $light, $amount: 50);
  }
} 

.btn-span {
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.btn-spinner {
  .spinner-border, .spinner-grow {
    margin-right: .5rem;
  }
}