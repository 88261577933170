@import '../../../sass/index.scss';

.mensajeria {
  p, label, time {
    color: $color-primary;
  }
  label {
    font-weight: 600;
  }
  a {
    color: $info;
  }
}