@import '../../../../sass/index.scss';

.cabecera {
  // background-color: $primary;
  padding: 5px;
  input {
    color: #1a202c !important;
    font-size: 14px;
    padding: 10px;
    height: 40px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    &:focus {
      border: 3px solid #bee3f8;
      box-shadow: $style-shadow;
    }
}
  button {
    background-color: $primary;
    display: block;
    width: 100%;
    margin-top: 1rem;
  }
}