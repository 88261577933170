@import '../../../sass/index.scss';

.filtro-respuesta {
  p{
   margin-bottom: 10px;
   font-weight: 500;     
  }
  .lista-sesiones {
    .form-check-inline {
      padding: 8px 5px;
      input {
        
      }
      label {
        font-size: 13px;
        color: $color-primary
      }
    }
   
  }
}