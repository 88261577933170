@import '../../../../sass/index.scss';

.tabla-reporte-encuestas {
  margin-bottom: 20px;
  width: 100%;
  table {
    thead {
      th {
        background-color: $primary;
        color: #fff;
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        &.th__fecha {
          min-width: 120px;
        }
        &.th__fecha--valor {
          min-width: 120px;
        }
        &.th__rating {
          min-width: 90px;
        }
      }
    }
    tbody {
      td{
        color: $color-primary;
        font-size: 11px;
        text-align: center;
      }
    }
    
    
  }
   
}