@import '../../../../sass/index.scss';

.info-encuesta {
  .content {
    padding: 40px;
    padding-bottom: 0;
  }
  .content-title {
    border-left: 3px solid $primary;
    padding-left: 10px;
    
  }
  &__resumen-encuesta {
    .content-description {
      padding-left: 15px;
      &-item {
        .subTitle {
          font-weight: 600;
        }
      }
    }
    p {
      color: $color-primary;
      margin-bottom: 5px;
    }
  }

  &__respondidos {
    .content-description {
      max-height: 300px;
      overflow-y: scroll;
    }
    
  }
}