@import '../../../sass/index.scss';

form {
    .btn-subir-imagen {
        background: $primary;
        color: #fff;
    }
    .form-group.a {
        .basic-single.css-2b097c-container {
            
            input[type=text] {
                padding: 4px 0 !important;
                margin-bottom: 0 !important;
            }
        }
        
    }
   
}
.tabla {
    input[type= search]{
        border-radius: 5px;
        font-size: 14px;
        padding: 18px 10px ;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        height: 10px;
        &:focus{
            border-color: #a3bffa;
        }
    }

    select {
        border-radius: 5px !important;
        
        font-size: 14px !important;
        padding-top: 5px !important;
        padding-bottom: 10px !important;
        -webkit-border-radius: 5px !important;
        -moz-border-radius: 5px !important;
        -ms-border-radius: 5px !important;
        -o-border-radius: 5px !important;
        &:focus {
            border-color: rgb(26, 57, 158);
        }
    }
}

