@import '../../../../sass/index.scss';

.ModalLarge {
  .modal-header {
    padding-left: 1rem;
    padding-right: 1rem;
    h4 {
      font-weight: 500;
      font-size: 1.2rem;
    }
  } 
  .modal-body {
    padding-right: 1.1rem;
    padding-left: 1.1rem;
    padding-bottom: .5rem;
  }
  .modal-footer {
    padding-left: 0;
    padding-right: 0;
  }
}