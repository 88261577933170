@import '../../../../sass/index.scss';

.CuotasDiscente {
  .datos-matricula {
    p:first-child {
      font-weight: bold;
    }
    p:last-child {
      margin-left: .5rem;
    }
  }
}