
.detalle {
  background-color: #FFF;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;

  border-radius: 5px; /* 5px rounded corners */
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

}

/* On mouse-over, add a deeper shadow */

/* Add some padding inside the card container */
.detalle-body {
  padding: 10px 25px;
  z-index:3;
}

.contenido-detalle{
  padding:5px;
  margin-top: 30px;

}

.image-detalle{
  position:absolute;
  padding:5px;
  border-radius:5px;
  margin-top: -50px;
  max-width: 70px; 
  background-color: white;
}

.detalle img {
  border-radius: 5px 5px 0 0;
}