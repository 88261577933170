@import '../../../../../sass/index.scss';

.relacion-docentes {
  color: $color-primary;
  .todo-docentes,
  .docentes-relacionados {
    &__lista {
      @include max-heigth-scroll(30rem);
      ul {
        padding-left: 0;
      }
    }
  }
  .list-docente-item {
    li {
      margin-top: 10px;
      padding: 5px 5px;
      text-decoration: none;
      list-style: none;
      .list-docente-item--direccion {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      
    }
  }
}