@import '../../../sass/index.scss';

.general-posventa {
 
  .tabla-reporte-general {
    
    td {
      min-width: 150px;
    }
    .estado-academico.rojo{
      background: $danger;
      color: #fff !important;
      border: 1px solid #ccc;
    }
    .estado-academico.verde {
      background: $success;
      color: #fff !important;
      border: 1px solid #ccc;
    }
    .estado-academico.ambar {
      background: $warning;
      color: #fff !important;
      border: 1px solid #ccc;
    }
    .telefono {
      min-width: 50px;
    }
    .nota{
      min-width: 20px;
      text-align: center;
    }
    .asistencia {
      min-width: 130px;
      text-align: center;
    }
    .estado-pago {
      min-width: 92px;
    }
    .observaciones {
      min-width: 200px;
    }
  }

  .reporte {
    table {
      tr th {
        border: 2px solid #000 !important;
        color: green !important;
      }
    }
  }

  .table-report {
    
    margin: 20px 0;
    .menu {
      margin-bottom: 15px;

      .select-entries{
        padding: 0 0;
        font-size: 13px !important;
        height: 32px;
        margin: 0 10px;
        width: 40px;
        border: 1px solid #ccc;
      }

      .content-button {
        .search {
          width: 180px;
          height: 20px;
          padding: 15px 10px !important;
          margin: 0 10px;
        }
      }
    }
    

    thead {

      tr{
        border-bottom: 1px solid #fff;
        th {
          padding: 5px;
          font-size: 11px;
          color: #fff;
          background: $primary;
          width: auto;
          font-weight: 500;
          text-align: center;
          user-select: none;
        }
        .agrandar {
          min-width: 200px !important;
          // display: block;
        }
        
        .agrandar.academico {
          min-width: 400px !important;
        }
        .sortable {
          position: relative;
          &::after {
            content: "\f0dc";
            display: inline-block;
            font-family: FontAwesome;
            font-size: 15px;
            vertical-align: middle;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            right: 5px;
            top: 25%;
          }
          &.asc {
            &::after {
              content: "\f0de";
              
            }
          }
          &.desc {
            &::after {
              content: "\f0dd";
            }
          }
        }
        .discente {
          min-width: 170px !important;
          text-align: center;
        }
        .nota {
          min-width: 80px;
        }
      }
    }

    
    tbody {
      tr {
        border-bottom: 1px solid #ccc;
        td {
          padding: 0px 15px;
          height: 10px;
          font-size: 12px !important;
          select {
            font-size: 12px !important;
            margin: 0;
          }
        }
        .td-semaforo {
          padding: 0;
          .semaforo {
            color: #fff;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
        }
        .nota {
          &__valor {
            color: $info;
            text-decoration: none;
            font-weight: bold;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        
      }
    }
    
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
  }
}

