@import '../../../../sass/index.scss';

.lista-propuestas {
  .propuesta {
    .texto {
      width: 50px !important;
      content: quote($string: "scscs");
      p {
        display: block;

      }
    }
    .acciones-propuesta {
      button {
        width: 30px;
        height: 25px;
        padding: 1.5px 3px 5px;
        svg {
          font-size: 12px;
        }
      }
    }
  }
} 