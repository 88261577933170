.embudo{
    display: flex;
    flex-direction: column;
}

.embudo_1{
    background: url('/images/1_embudo.png');
    height:100px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position:center;
    color: #000;
    h3{
        color: #000;
    }

}

.embudo_2{
    background: url('/images/2_embudo.png');
    height:100px;
    background-size: 65%;
    background-repeat: no-repeat;
    background-position:center;
    color:#000;
    h3{
        color: #000;
    }
}
.embudo_3{
    background: url('/images/3_embudo.png');
    height:100px;
    background-size: 30%;
    background-repeat: no-repeat;
    background-position:center;
    color:#fff;
    h3{
        color:#fff
    }
}

.embudo_1,.embudo_2,.embudo_3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 414px) {
    .embudo_1,.embudo_2,.embudo_3{
        height:110px
    }
}

@media screen and (min-width: 539px) {
    .embudo_1,.embudo_2,.embudo_3{
        height:150px
    }
}

@media screen and (min-width: 768px) {
    .embudo_1,.embudo_2,.embudo_3{
        height:110px
    }
}

@media screen and (min-width: 1023px) {
    .embudo_1,.embudo_2,.embudo_3{
        height:110px
    }
}

@media screen and (min-width: 1025px) {
    .embudo_1,.embudo_2,.embudo_3{
        height:170px;
        font-size:24px;
    }
}
