@import '../../../../../sass/index.scss';

.VistaDatosPersonales {
  span, p {
    color: $color-primary;
  }
  .datos-info {
    &__top {
      font-weight: 500;
      font-size: .9rem;
    }
    &__bottom {
      padding-left: 2.5rem;
      font-weight: 300;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
  
  svg {
    color: $color-primary;
    margin-right: .4rem;
  }
}