@import '../../../../../sass/index.scss';

.acciones-tabla-control {
  color: $color-primary;
  &__subtitle {
    margin: 10px 0;
    font-size: 1rem;
    font-weight: 400;
    padding: 5px;
  }

  .lista-docentes__scroll {
    @include max-heigth-scroll(20rem);

  }

  .images-confirmacion {
    &__content {
      margin: 1.4rem 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      picture {
        position: relative;
        text-align: center;
        flex-basis: calc(15.3% - 1rem);
        margin-right: 1.5rem;
        margin-bottom: 10px;
        cursor: pointer;
        button {
          position: absolute;
          top: -5px;
          right: -10px;
          padding: 0 8px;
          text-align: center;
          svg: {
            font-size: 9px;
          }
        }
      }
    }
  }
}