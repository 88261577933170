@import '../../../sass/index.scss';

.main-evento {
  border-top: 3px solid $warning !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: $style-shadow;
  color: #1E2B37 !important;
  
  &__left-content,
  &__right-content {
    .sub-title {
      font-size: 18px;
      font-weight: 600;
    }

    .lista-botones {
      &__item {
        background-color: #fff !important;
        color: $color-primary;
        padding: 0;
        font-size: 13px !important;
        &:hover {
          color: $secondary-hover;
        }
      }
    }
  }

  &__left-content {
    
    .contacto-tutor {
      svg {
        margin-right: 10px;
        font-size: 14px;
      }
    }
  }

  &__right-content {
    p {
      margin-bottom: 0;
    }
  }
   
  
}