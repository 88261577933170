@import '../../../sass/index.scss';

.lista-encuestas {
  .btn-nuevo-cuestionario {
    width: 100%;
    min-height: 250px;
    height: 95%;
    display: flex;
    flex-direction: column;
    color: #1E2B37 !important;
    font-weight: 200;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    box-shadow: $style-shadow;
    svg {
      font-size: 25px;
      margin-bottom: 5px;
    }
    &:hover{
      box-shadow: $style-shadow-secondary;
    }
  }
}