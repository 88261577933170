@import '../../../../../sass/index.scss';

.VistaClasificacionDocente, .VistaClasificacionTrabajador {
  color: $color-primary;
  
}

.ListaClasificacion__list li{
  float:left;
  max-width: 150px;
  padding:2px;

}
