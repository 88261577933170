@mixin widthModal($width: 30vw) {
  max-width: $width;
  @media (max-width: 615px) {
    max-width: 100vw;
  }
}

.modal-w40 {
  @include widthModal(39vw);
}

.modal-w30.modal-dialog {
  @include widthModal(30vw);
}

.modal-w60.modal-dialog {
  @include widthModal(60vw);
}
.modal-wl.modal-dialog {
  @include widthModal(70vw);
}

.custom-modal {
  .modal-header {
    padding-left: 1.1rem;
    padding-right: 1rem;
    .h4 {
      font-weight: 400;
      font-size: 1.2rem !important;
    }
  }
}