@import '../../../../sass/index.scss';

.encuesta-docente {
  width: 100%;
  display: flex;
  justify-content: center;
  color: $color-primary;
  .content-resultado-encuesta {
    width: 70%;
    background-color: #fff;
    height: 500px;
    padding: 30px 60px;
    &__titulo {
      font-weight: 400;
      font-size: 20px;
    }
    &__respuestas {
      margin-top: 50px;
      .repuestas__item {
        padding: 30px 0;
        border-top: 1px solid #ddd;
        p.label {
          font-size: 17px;
          font-weight: 300;
        }
        .respuesta {
          font-style: italic;
        }
        .subpregunta {
          margin-bottom: 15px;
          &__label {
            border-left: 2px solid $primary;
            padding: 2px 5px;
            margin-bottom: 5px;
          }
          &__respuesta {
            padding-left: 80px !important;
            font-style: italic !important;
          }
        }
      }
    }
  }
}