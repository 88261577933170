//Colores de pagina
$primary: #27314A; //#27314A ff532b;
$secondary:#3A4C7E; //#3A4C7E;
$success: #1ABB9C;
$succes-light: #1ABB9C;
// $succes-light: #e28080;
// $succes-light: #57bcdb;
$danger: #E74C3C;
$warning: #E3882E;
$info: #0069D9;
$info-light: #F8F9FD;
$green-light: #F6F8FA;
$light: #e7e7e7;
$dark: #1a1a1a;

//Hover
$primary-hover: #27314A;
$secondary-hover: #3A4C7E;
$success-hover: #13967D;
$danger-hover: #E12E1C;
$warning-hover: #D4860B;
$info-hover: #138496;

//Sombras
$style-shadow: 0 0 5px rgba(0,0,0,.20);
$style-shadow-secondary: 0 .125rem .25rem rgba(0,0,0,.50);

//Fuentes
$color-primary: #6e6e70;