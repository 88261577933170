@import '../../../../sass/index.scss';

.content_new_usuario, .nav-perfil-sesion{
    background: #fff;
    padding: 50px 30px;
    box-shadow: 1px 1px 5px #ccc;
    margin-top: 20px;
    .avatar-usuario{
        margin: auto;
        width: 50%;
    }
    input, select{
        font-size: 13px;
        text-transform: none !important;
    }

}
.nav-perfil-sesion{
    padding: 0;
    .tab-pane{
        padding: 30px 10px;
    }
}
.tab-content{
    min-height: 500px;
}
.content-sesion-usuario{
    width: 100%;
    height: 100%;
    form{
        width: 35%;
        height: 100%;
        input{
            font-size: 13px;
        }
        .form-row{
            margin-bottom: 15px;
        }
        .btn.submit{
            margin-top: 50px;
        }
        .change-userName{
            background: $primary;
            color: #fff;
            &:hover{
                background: $primary-hover;
                color: #fff;
            }
        }
        .change-pass{
            background: $primary;
            color: #fff;
            &:hover{
                background: $primary-hover;
                color: #fff;
            }
        }
    }
}

//Modal
.editar-usuario, .editar-password{

    &__form{
        width: 100% !important;
        padding: 0 20px;

        &__botones{

            margin-top: 20px;
    
            .button-guardar{
                background: $danger;
                color: #fff;
                &:hover{
                    background: $danger-hover;
                }
            }
            .button-reset{
                background: $secondary;
                color: #fff;
        
                &:hover{
                    background: $secondary-hover;
                }
            }
        }
    }
}