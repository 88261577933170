@import '../../../../sass/index.scss';

.CardPlan {
  margin-bottom: 10px;
  .card-header {
    font-weight: bold;
    background-color: $green-light;
    color: $primary;
  }
  .card-footer {
    text-align: right;
    background-color: #F6F8FA;
  }
}