table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0 !important;
  &.table-striped {
    th {
      background-color: #F6F8FA;
    }
    tr:nth-child(even) {
      background-color: #F6F8FA;
    }
  }
  th,
  td {
    vertical-align: middle !important;
    border: 1px solid #f4f4f4;
    padding: .5rem .5rem;
    line-height: 1.42857143;
    vertical-align: top;
    .btn {
      margin-bottom: 0;
    }
  }
  
  th {
    text-align: center;
    padding: .8rem .5rem;
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;
  }
}
