@import '../../../sass/index.scss';

.IndicadorEstado {
  .estado {
    cursor: pointer;
    font-size: .7rem;
  }
  .estado.danger {
    background-color: lighten($color: $danger, $amount: 7);
    color: darken($color: $danger, $amount: 40);
  }
  .estado.success {
    background-color: lighten($color: $success, $amount: 30);
    color: darken($color: $success, $amount: 40);
  }
}