#clock {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 20px auto 0 auto;
    background: url(./ImgsClock/clockface.png);
    list-style: none;
}

#sec, #min, #hour {
    position: absolute;
    width: 13px;
    height: 250px;
    top:-2px;
    left: 119px;
    }

#sec {
    background: url(./ImgsClock/sechand.png);
    z-index: 3;
       }
   
#min {
    background: url(./ImgsClock/minhand.png);
    z-index: 2;
       }
   
#hour {
    background: url(./ImgsClock/hourhand.png);
    z-index: 1;
       }

#digital{
    font-size: 25px;
    width: 100%;
    text-align: center;
    color:rgb(0, 0, 0);
}