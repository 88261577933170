.nav-tabs{ 
  background-color: #fff;
  border-top: 1px solid #f5f5f5;
  a {
    color: $info;
    padding: .9rem 2.7rem;
    &.nav-link {
      border-radius: 0;
      border-top: 3px solid transparent;
      font-size: .9rem;
      font-weight: 600;
      background-color: #fff;
      margin-bottom: 0;
      &:hover {
        border-top: 3px solid lighten($color: $dark, $amount: 50);
        border-bottom-width: 0;
      }
    }
    &.nav-link.active {
      border-top: 3px solid $info;
      margin-bottom: -1px;
    }
  }
}

.tab-content {
  background-color: #fff;
  padding: 1.5rem 2rem;
}