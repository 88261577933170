
.TablaListadoNotas {
    .tableFixHead          {  height: auto; background: #fff; margin-bottom: 15px; }
.tableFixHead thead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
table.notas  { border-collapse: collapse; width: 100%; height: 500px; }
.tableFixHead td {font-size: 13px; padding: 5px 16px; }
.tableFixHead thead th{
    font-size: 14px;
    padding: 3px auto;
}
.notas tbody tr td{
    font-size: 13px !important;
    padding: 10px 15px;
    color: rgb(25, 25, 25) !important;
}

.tableFixHead thead{
        display: table-header-group;
        vertical-align: middle;
        align-items: center;
        border-color: inherit;
}
}
