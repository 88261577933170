
body{
  background: #ECEFF4 !important;
  font-family: 'Source Sans Pro', sans-serif;
  padding-right: 0 !important;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.right_col{
  background: #ECEFF4 !important;
}


* {
  scrollbar-color: #A5B0C4;
  scrollbar-width: thin;
}

p {
  margin-bottom: 0;
}

button, input{
  font-family: 'Source Sans Pro', sans-serif;
}
h1,h2, h3, h4, h5, h6{
  color: $color-primary;
  font-weight: 600;
}

h4 {
  font-size: 1.3rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
