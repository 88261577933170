@import '../../../../../sass/index.scss';

.ponente-ctrl {
  table {
    background-color: #fff;
    th ,td {
      padding: 5px;
    }
    td {
      color: $color-primary;
    }
  }
  .tableFixHead {
    overflow-y: auto;
    height: 205px;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  
  /* Just common table stuff. Really. */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .pagos h5 {
    font-size: 14px;
  }
  
  .tableFixHead thead th {
    font-size: 13px !important;
  }
  
  .tableFixHead tbody td {
    font-size: 13px;

  }
  
}