@import '../../../../sass/index.scss';

.lista-agenda {
  padding: 5px 12px;
  .acciones {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      color: #fff;
    }
    .estado {
      padding: 2px 10px;
      height: 25px;
      &.completo {
        background-color: #9ae6b4;
        color: $color-primary;
      }
      &.incompleto {
        background-color: #FBDCDE;
        color: $color-primary;
      }
    }
    .btn-eliminar {
      background-color: $dark;
      color: $color-primary;
    }
    .btn-editar {
      background-color: #2d3748;
     
    }
  }
  &__item {
    margin-top: 15px;
    padding: 15px 0;
    background-color: #fff;
    box-shadow: $style-shadow;
  }
}