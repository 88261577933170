@import "../../../../sass/index.scss";

.RegistroPagos {
  .tableFixHead {
    overflow-y: auto;
    height: 300px;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  
  
  .pagos h5 {
    font-size: 14px;
  }
  
  .pagos .item.info-estudiante {
    font-size: 14px !important;
    font-weight: 600;
  }
  .pagos .item.info-estudiante span {
    margin-left: 5px;
    font-weight: normal;
  }
  
  .tableFixHead thead th {
    font-size: 14px !important;
  }
  
  .tableFixHead tbody td {
    font-size: 14px !important;
    padding: 0px;
  }  
}